<template>
<div class="liff-course-list-page">
  <!-- Lession Type Selector -->
  <div class="lession-selector" align="center">
    <a-select
      v-model:value="lessionTypeValue"
      :options="lessionTypeOptions"
      size="large"
    />
  </div>
  <!-- Lession Type Selector -->

  <!-- Lession Cards -->
  <div class="lession-card-list">
    <template v-if="filteredData.length > 0">
      <a-row>
        <a-col
          v-for="(lession, lessionIdx) in filteredData"
          :span="24"
        >
          <div class="lession-card">
            <div class="card-title">
              <!-- <span class="title-number">{{ lession.name }}</span> -->
              <span class="title-name">{{ lession.name }}</span>
            </div>

            <div class="card-body">
              <div class="body-header">
                <span class="header-time">{{ lession.start_datetime_formated.month }}/{{ lession.start_datetime_formated.date }} {{ courseData.start_time }}</span>
                <span class="header-name">{{ courseData.classroom }}</span>
              </div>

              <div class="body-description">
                {{ lession.description }}
              </div>
            </div>

            <div class="card-actions">
              <a-button
                class="action-btn"
                type="primary"
                :style="{ background: '#FF8A00', borderColor: '#FF8A00' }"
                @click="toLessionPage(lession.id)"
              >查看課堂</a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>

    <template v-else>
      <a-empty
        class="py-6"
        :image="simpleImage"
        description="課堂都已經完成"
        :style="{ fontSize: '1.3rem', color: '#656565' }"
      />
    </template>
  </div>
  <!-- Lession Cards -->

  <!-- 簽到區塊 -->
  <div class="page__actions">
    <template v-if="!isCourseCompleted">
      <div class="action-btn" :style="{ color: '#FF8A00' }" @click="toRollcallPage('lession_pre')">
        課前簽到
      </div>
      <div class="action-btn" @click="toRollcallPage('lession_post')">
        課後調查
      </div>
    </template>

    <template v-else>
      <div class="action-btn btn--disabled" :style="{ width: '100%' }">
        課堂結束
      </div>
    </template>
  </div>
  <!-- 簽到區塊 -->
</div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Data */
    const courseData = ref({});
    // const usersData = ref([]);
    // const coachesData = ref([]);
    // const assistantsData = ref([]);
    const lessionsData = ref([]);
    const upcomingLessionData = ref({});
    const isCourseCompleted = computed(() => {
      if (Object.keys(upcomingLessionData.value).length == 0) return true;
      else return false;
    });

    /* Lession */
    const lessionTypeValue = ref('readyToStart');
    const lessionTypeOptions = ref([{
      label: '即將上課',
      value: 'readyToStart'
    }, {
      label: '所有課堂',
      value: 'all'
    }, {
      label: '未開課',
      value: 'unstarted'
    }, {
      label: '已完成的課堂',
      value: 'finished'
    }]);

    /* Api */
    const getCourseData = (async () => {
      courseData.value = {};
      
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
    });

    const getLessionsData = (async () => {
      lessionsData.value = [];
      
      const response = await api.v1.lession.list({
        course_id: route.params.course_id
      });

      response.data.result.forEach((item, idx) => {
        // item.name = `課堂${idx+1}：${item.name}`
        const dateRes = DateUtil.formalizeDate(item.start_date);
        item.start_datetime_formated = dateRes;
        lessionsData.value.push(item);

        // 計算從今天起計算，最靠近的日期
        if (Object.keys(upcomingLessionData.value).length == 0) {
          if (moment().isBefore(`${item.start_date} 23:59:59`)) {
            upcomingLessionData.value = item;
          }
        }
      });

      console.log(lessionsData.value);
    });

    /* Mounted */
    onMounted(async () => {
      await getCourseData();
      await getLessionsData();

      if (isCourseCompleted.value) {
        lessionTypeValue.value = 'all';
      }
    });

    return {
      /* Lession */
      lessionTypeValue,
      lessionTypeOptions,

      /* Data */
      courseData,
      // coachesData,
      // assistantsData,
      // usersData,
      lessionsData,
      upcomingLessionData,
      isCourseCompleted,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  computed: {
    filteredData() {
      let data = [];
      
      if (this.lessionTypeValue == 'readyToStart') {
        if (Object.keys(this.upcomingLessionData).length > 0) data.push(this.upcomingLessionData);
      }
      else if (this.lessionTypeValue == 'all') data = this.lessionsData;
      else if (this.lessionTypeValue == 'unstarted') {
        this.lessionsData.forEach(item => {
          if (moment().isBefore(item.start_date)) {
            data.push(item);
          }
        });
      }
      else if (this.lessionTypeValue == 'finished') {
        this.lessionsData.forEach(item => {
          if (moment().isAfter(`${item.start_date} ${this.courseData.end_time}`)) {
            data.push(item);
          }
        })
      }

      return data
    },
  },
  methods: {
    /* Router */
    toLessionPage(lession_id) {
      this.$router.push({
        name: 'LiffLessionPage',
        params: {
          course_id: this.$route.params.course_id,
          lession_id: lession_id
        }
      });
    },
    toRollcallPage(mode) {
      this.$router.push({
        name: 'LiffRollcallPage',
        params: {
          course_id: this.$route.params.course_id,
        },
        query: {
          mode: mode
        }
      });
    }
  }
})
</script>

<style lang="scss">
.liff-course-list-page {
  padding-bottom: 60px;

  .lession-selector {
    .ant-select {
      width: 70%;
      background: white;
      border-radius: 0;
      font-size: 1.2rem;
      margin-bottom: 16px;

      .ant-select-selection-item {
        text-align: center;
      }
    }
  }
  
  .lession-card-list {
    .lession-card {
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 4px;
      margin-bottom: 20px;

      .card-title {
        padding: 12px 20px;
        border-bottom: 1px solid #E0E0E0;
        font-weight: bold;

        .title-number {
          color: #FF8A00;
          font-size: 1.3rem;
          margin-right: 4px;
        }

        .title-name {
          color: #212121;
          font-size: 1.25rem;
        }
      }

      .card-body {
        padding: 12px 20px;
        border-bottom: 1px solid #E0E0E0;

        .body-header {
          margin-bottom: 8px;
          font-size: 1.3rem;
          
          .header-time {
            color: #FF8A00;
            font-weight: 500;
          }

          .header-name {
            color: #FF8A00;
            font-weight: 400;
            float: right;
          }
        }
        
        .body-description {
          color: #424242;
          font-size: 1rem;
        }
      }

      .card-actions {
        padding: 12px 20px;

        .action-btn {
          width: 100%;
          font-size: 1.2rem;
          height: 44px;
        }
      }
    }
  }

  .page__actions {
    width: 100%;
    height: 50px;
    position: fixed;
    left: 0;
    bottom: 0;

    .action-btn {
      display: inline-block;
      width: 50%;
      height: 50px;
      background: white;
      box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.05);
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 50px;
      text-align: center;

      &.btn--disabled {
        color: #424242;
      }
    }
  }
}
</style>